.App {
  text-align: center;
  background-color: rgb(117, 146, 231);
  height: 100%;
}

*{
  margin: 0;
}



/* for vipplan.jsx */
.vip{
  text-align: center;
  margin: auto;
  color: white;
  background-color: rgb(80, 80, 188);
  padding-bottom: 10000vh;
  margin-bottom: 10000vh;
}

.vip .card{
  color: white;
  display: flex;
  margin: auto;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 4vh;
  border-radius: 2vh;
  margin-top: 3vh;
  background-color: rgb(52, 67, 154);
 
}






.vip .card p{
  color: white;
}



/* css for taskpage */
.taskpage{
  color: whitesmoke;
  background-color: rgb(77, 77, 239);
}

.taskpage .task{
  border-radius: 2vh;
  margin-top: 3vh;
  border: 1vh solid rgb(209, 47, 214);
  margin-left: 2vh;
  margin-right: 2vh;
  padding-left: 2vh;
  padding-right: 2vh;
  box-sizing: border-box;
}

.taskpage .task button{
  margin-top: 4vh;
  margin-bottom: 3vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 3vh;
  padding-right: 3vh;
  border-radius: 2vh;
  border: 1vh solid rgb(226, 43, 183);
  color: white;
  background-color: rgb(226, 43, 183);
}

.taskpage .task button:hover{
  color: rgb(77, 77, 239);
}



/* css for register page */
.register{
  overflow: auto;
  color: white;
  text-align: center;
  background-color: rgb(209, 47, 214);
}


.register h3{
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.register form button{
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 3vh;
  padding-right: 3vh;
  border-radius: 2vh;
  border: 1vh solid rgb(226, 43, 183);
  color: white;
  background-color: rgb(226, 43, 183);
}

.register form button:hover{
  color: aqua;
  border-color: aquamarine;
}

.register form input{
  height: 3vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 3vh;
  margin-top: 1vh;
  border-radius: 1vh;
  border: 1vh solid rgb(226, 43, 183);
}

.register form input:focus{
  border: 1vh solid rgb(226, 43, 183);
}




/* css for login page */
.login{
  color: white;
  background-color: rgb(226, 43, 183);
   
}




.login h2{
  padding-top: 3vh;
}

.login form{
  margin-top: 4vh;
}

.login form input{
  margin-top: 3vh;
  height: 3vh;
}

.login form label{
  margin-top: 3vh;
}

.login form button{
  margin-top: 3vh;
  margin-bottom: 4vh;
  padding-left: 6vh;
  padding-right: 6vh;
  padding-top: 1vh;
  padding-bottom: 1.5vh;
  color: white;
  border-radius: 1vh;
  border: 1vh solid rgb(226, 43, 183);
  background-color: rgb(209, 47, 214);
}

.login form button:hover{
  border: 1vh solid rgb(77, 77, 239);
}
.login form button:active{
  background-color: rgb(77, 77, 239);
}




/* css dor homepage */
.home-page h1{
  margin-top: 20vh;
  color: white;
}

.home-page .para{
  color: white;
  padding-bottom: 3vh;
}
  /* background-color: black; */
  



.banner {
  width: 100%;               /* Full width of the page */
  overflow: hidden;          /* Hide any overflow of text */
  background-color: rgb(226, 43, 183);    /* Banner background */
  padding: 10px 0;           /* Some padding for the banner */
}

.banner-text {
  display: inline-block;      /* Keep the text on one line */
  white-space: nowrap;        /* Prevent the text from wrapping */
  color: #fff;                /* Text color */
  font-size: 24px;            /* Font size for visibility */
  animation: moveBanner 30s linear infinite; /* Apply the animation */
}

/* Keyframes to move the banner text */
@keyframes moveBanner {
  0% {
      transform: translateX(100%); /* Start from left */
  }
  100% {
      transform: translateX(-100%);  /* End off-screen to the right */
  }
}



.home-page .home-btn{
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  padding-left: 4vh;
  padding-right: 4vh;
  border-radius: 1.5vh;
  background-color: rgb(226, 43, 183);
  border: .5vh;
  color: white;
  margin-bottom: 4vh;
  margin-top: 4vh;
}

.home-page .home-btn:active{
  background-color: rgb(209, 47, 214);
}



/* .home-page .logo-img{
  height: 7vh;
  width: 15vh;
  margin-top: 2vh;
  
} */

#home-img{
  width: 30vh;
  display: block;
  margin: 0 auto;
  padding-top: 3vh;
}


.home-page .logo-img{
    height: 7vh;
    width: 15vh;
    border-radius: 4vh;

    position: fixed;
    top: 25vh;
    left: 5%;
    cursor: pointer;
    border-radius: 1vh;
    z-index: 1;
  
}

.home-page .bonus-details{
  color: rgb(255, 255, 255);
  background-color: rgb(226, 43, 183);
  border: 1vh solid yellowgreen;
  border-radius: 2vh;
  margin-left: 2vh;
  margin-right: 2vh;
  margin-bottom: 2vh;
  padding-bottom: 4vh;
  
}




/* css for footer*/
/* .footer p{
  display: inline;
  background-color: antiquewhite;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);

} */


.footer {
  float: inline-end;
  position: fixed;      /* Fixes the footer to the bottom */
  bottom: 0;           /* Aligns it to the bottom of the viewport */
  left: 0;             /* Aligns it to the left of the viewport */
  width: 100%; 
  height: 3vh;        /* Covers the full width */
  background-color: rgb(226, 43, 183); /* Background color of the footer */
  color: white;        /* Text color */
  text-align: center;  /* Center the text */
  padding: 10px;       /* Padding around the content */
}




/* css for prehome*/
/* .prehome{
  background-color: rgb(117, 146, 231);
  height: 100%;
} */


.prehome .blank{
  display: flex;
  flex-direction: column;
  min-height: 100%; 
  background-color: rgb(117, 146, 231);
}


.prehome button{
  /* padding-bottom: 3vh; */
  margin-bottom: 10vh;
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-radius: 1.5vh;
  border: 1vh solid rgb(226, 43, 183);
  margin-top: 3vh;
}

.prehome button:focus, .prehome button:active{
  background-color: rgb(226, 43, 183);
  color: white;
}



/* css for getuserprofile */
.get-user-profile img{
  border-radius: 50%;
  margin-top: 3vh;
  margin-bottom: 2vh;
}


/* css for reset pass*/
.resetpass{
  color: white;
}

.resetpass .pass-reset-direction{
  border: 2vh solid rgb(226, 43, 183);
  border-radius: 1vh;
  margin-left: 2vh;
  margin-right: 2vh;
  margin-top: 3vh;
  margin-bottom: 4vh;
  background-color: rgb(209, 47, 214);
}

.resetpass .rest{
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-radius: 1.5vh;
  margin-top: 3vh;
  border: 1vh solid rgb(117, 146, 231);
}


.resetpass button:hover{
  background-color: rgb(226, 43, 183);
}
.resetpass button:focus{
  background-color: rgb(209, 47, 214);
}




/*css for editprofile */
.edit-profile h2{
  color: white;
  margin-top: 3vh;
}

.edit-profile label{
  color: white;
  margin-top: 4vh;
}

.edit-profile .edit-btn{
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin-top: 3vh;
  margin-bottom: 4vh;
  border-radius: 1vh;
  border: 1vh;
}


.edit-profile .edit-btn:active{
  background-color: rgb(209, 47, 214);
  color: white;
}


/*CSS DOR WITHDRAW PAGE*/
.withdraw{
  color: white;
}

.withdraw form .w-btn{
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin-top: 3vh;
  margin-bottom: 4vh;
  border-radius: 1vh;
  border: 1vh;
}

.withdraw form .w-btn:active{
  background-color: rgb(209, 47, 214);
  color: white;
}


.withdraw .d{
  color: aliceblue;
  border: 1vh solid rgb(214, 47, 131);
  margin-left: 3vh;
  margin-right: 3vh;
  margin-top: 3vh;
  margin-bottom: 4vh;
  padding: 3vh;
  border-radius: 1vh;
}


/*css for activation */
.activation{
  overflow-x: hidden;
  overflow-y: auto;
  color: white;

  width: 100%;
}


.activation .direction{
  border: 1.5vh solid yellowgreen;
  border-radius: 3vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
  margin-left: 4vh;
  margin-right: 4vh;
  padding-left: 2vh;
  padding-right: 2vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  color: blue;
}


.activation .direction p{
  margin-top: 3vh;
}



.activation form{
  width: 100%;
  /* border: 0.5vh solid rgb(226, 43, 183); */
  border-radius: 2vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
  /* margin-left: 4vh; */
  margin-right: 4vh;
  /* padding-left: 2vh; */
  padding-right: 2vh;
  padding-top: 2vh;
  padding-bottom: 2vh;

}

/* .activation form p{
  margin-right: 3vh;
} */


.activation form .a-btn{
  /* width: 100%; */
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin-top: 3vh;
  margin-bottom: 4vh;
  border-radius: 1vh;
  border: 1vh;
}


.activation form .a-btn:active{
  background-color: rgb(209, 47, 214);
  color: white;
}


input{
  height: 3vh;
  margin-bottom: 1vh;
}


/*css for sponsor page*/
.sponsor{
  color: white;
}


/*css for emailtask */
.email-task{
  color: white;
}

.email-task p{
  margin-top: 3vh;
}

.email-task a{
  margin-bottom: 3vh;
  text-decoration: none;
}

.sponsor .bonus-details{
  border: 1vh solid rgb(226, 43, 183);
  margin-top: 2vh;
  margin-left: 3vh;
  margin-right: 3vh;
  margin-bottom: 1vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 1vh;
  padding-right: 1vh;
  border-radius: 2vh;
}


.sponsor .bonus-details p{
  margin-top: 2vh;
}


.sponsor .code{
  margin-top: 2vh;
  margin-bottom: 4vh;
  background-color: rgb(226, 43, 183);
  border: 1vh solid rgb(117, 146, 231);
  border-radius: 2vh;
  padding-left: 2vh;
  padding-right: 2vh;
}


/*css for help page*/

.help h2{
  color: white;
  margin-top: 6vh;

}

.help h2 a{
  color: white;
}


.help .img{
  width: 20vh;
  height: auto;
   margin-top: 5vh; 
}


.help .section{
  margin-left: 3vh;
  margin-right: 3vh;
  border: 1vh solid rgb(226, 43, 183);
  border-radius: 3vh;
  margin-top: 2vh;
  padding-bottom: 2vh;
  background-color: rgb(209, 47, 214);
}

.help .section a{
  text-decoration: none;
}

.help .section h2:hover{
  color: yellowgreen;
}


#last{
  margin-bottom: 5vh;
}



/*css for task1page */
.task1 .task11{
  margin-left: 5vh;
  margin-right: 5VH;
  margin-top: 2vh;
  padding-top: 2vh;
  padding-left: 2vh;
  padding-right: 2vh;
  padding-bottom: 3vh;
  margin-bottom: 4vh;
  background-color: rgb(226, 43, 183);
}

.task1 .task-form{
  background-color: rgb(209, 47, 214);
  margin-left: 2vh;
  margin-right: 2vh;
  margin-top: 3vh;
  margin-bottom: 4vh;
  border-radius: 2vh;
}


.task-form div{
  color: white;
  /* margin-top: 3vh; */
  padding-top: 3vh;
  /* margin-bottom: 4vh; */
}

.task-form div p{
  color: white;
  font-size: 3vh;
}


.task-form div input{
  height: 3vh;
  background-color: white;
  /* background-color: rgb(226, 43, 183); */
}

.task-form .create-btn{
  padding-bottom: 1.5vh;
  padding-top: 1vh;
  padding-left: 3vh;
  padding-right: 3vh;
  border-radius: 1vh;
  margin-top: 3vh;
  margin-bottom: 4vh;
}

.task-form .create-btn:hover{
  color: white;
  
  background-color: rgb(214, 47, 131);
}



/*css for withdrawcreate*/
.withdraw-create{
  border: 2vh solid rgb(226, 43, 183);
  border-radius: 3vh;
  background-color: rgb(226, 43, 183);
  margin-left: 3vh;
  margin-right: 3vh;
  margin-top: 1vh;
  margin-bottom: 4vh;
  padding: 3vh;
}

.withdraw-create form div label{
  margin-top: 3vh;
}

/* .withdraw-create form div{
  margin-top: 3vh;
} */


.big{
  /* margin-top: 3vh; */
  height: 3vh;
}


.withd{
  padding-left: 30vh;
}




/*css for withdraw-list */
.withdraw-list{
  margin-bottom: 8vh;
  padding-bottom: 10vh;
  background-color: rgb(209, 47, 214);
  margin-left: 3vh;
  margin-right: 3vh;
  margin-top: 1vh;
  border-radius: 1vh;
  text-align: center;
}

.withdraw-list ul{
  text-align: center;
  margin: 0 auto;
}

.withdraw-list ul li{
  text-align: center;
  list-style-type: none;
  margin: 0 auto;
}


li table{
  text-align: center;
  margin: 0 auto;
}

th,td{
  border: 1px solid white;
  margin-top: 3vh;
  font-size: 150%;
}