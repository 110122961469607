.Menu {
    font-family: Arial, sans-serif;
    text-align: center;
    position: relative;
    color: white;
  }
  
  .menu-btn {
    position: fixed;
    top: 25vh;
    right: 5%;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 1vh;
    z-index: 2;
  }
  
.menu-btn:hover{
  color: #007bff;
  background-color: #ea2fbf;
}

.menu-btn:focus{
  color: white;
  background-color: #a43ee8;
}

  .side-menu {
    position: fixed;
    top: 0;
    left: -250px; /* Initially hidden */
    width: 250px;
    height: 100%;
    background-color: #fefefe;
    color: white;
    padding-top: 60px;
    transition: 0.5s; /* Smooth transition for slide */
    overflow-y: auto;
  }
  
  .side-menu.open {
    left: 0; /* Slide in */
    z-index: 2;
  }

  .side-menu p{
    background-color: rgb(168, 39, 160);
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 1vh;
  }


  .side-menu .card{
    display: flex;
    justify-content: space-between;
    /* margin-left: 2vh; */
    /* margin-right: 2vh; */
    margin-top: 4vh;
    width: 100%;
  }
  .side-menu .card div{
    background-color: #007bff;
    /* padding-top: 1vh; */
    padding-bottom: 1vh;
    padding-left: 1vh;
    padding-right: 2vh;
    /* margin:3vh; */
    border-radius: 2vh;
  }

  .side-menu .card div p{
    margin-top: 3vh;
    color: white;
  }

  .side-menu.close {
    left: -250px; /* Slide in */
  }
  
  .side-menu ul {
    list-style-type: none;
    padding: 0;
    overflow-y: auto;
    margin-bottom: 8vh;
  }
  
  .side-menu ul li {
    padding: 8px 16px;
    background-color: #007bff;
    border-radius: 2vh;
    margin-top: 2vh;
  }


  .side-menu ul li:hover{
    color: white;
    background-color: #5899df;
  }
  
  .side-menu ul li a {
    color: white;
    text-decoration: none;
  }
  
  .side-menu ul li a:hover {
    background-color: #575757;
    display: block;
  }
  