*{
    margin: 0;
}

.navbar .nav-ul{
    /* testing css*/
    




    margin-top: 0;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    background-color: rgb(5, 174, 93);
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    color: white;
    /* margin-right: 8vh; */
     /* Horizontal, Vertical, Blur, Spread, Color */

}


.navbar .nav-ul div img{
    margin-top: 0;
    width: 2vh;
    height: 2.5vh;
}


.navbar .nav-ul .nav-element-element{
    margin-top: 0;
    display: flex;
    align-items: center;
    margin: auto;
    background-color: blue;
    padding: 1.5vh;
    border-radius: 1vh;
    
}

.navbar .nav-ul .nav-element-element:last-child{
    margin-right: 6vh;
    
}


.navbar div img{
    width: 5vh;
}

.navbar .sec-top{
    display: flex;
    justify-content: space-between;
    padding: 3vh;
    align-items: center;
    background-color: rgb(212, 40, 234);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
}





@media only screen and (max-width: 768px) {
    .nav-ul{
        margin-top: 0;
        
        overflow-x: hidden;
        overflow-y: auto; 
          
        box-sizing: border-box;
        width: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1px; 
        /* padding: 10px; */
        border-color: aqua;
     }  


    .nav-element-element{
        margin-top: 0;
        box-sizing: border-box;
        /* display: flex; */
        /* align-items: center; */
        /* margin: auto; */
        background-color: rgb(114, 187, 57);
        /* padding: 1.5vh; */
        /* border-radius: 1vh; */
    }

    .nav-ul div img{
        width: 1vh;
    }

}