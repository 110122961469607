/*css for vipproof submit*/

.upload-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .upload-container h2 {
    text-align: center;
  }
  
  .upload-container form {
    display: flex;
    flex-direction: column;
  }
  
  .upload-container form div {
    margin-bottom: 10px;
  }
  
  .upload-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .upload-container input[type="text"],
  .upload-container input[type="file"] {
    padding: 8px;
    font-size: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upload-container button {
    padding: 10px;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-container button:disabled {
    background-color: #a9a9a9;
  }
  
  .upload-container p {
    margin-top: 10px;
    text-align: center;
  }
  


  /*css for vip-balance*/
  /* Vip.css */
.vip-balance {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .vip-balance h2 {
    color: #343a40;
    font-size: 24px;
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
  }
  
  .vip-balance p {
    font-size: 18px;
    color: #495057;
    font-family: 'Arial', sans-serif;
  }
  
  .vip-balance p.loading {
    font-style: italic;
    color: #868e96;
  }
  
  .vip-balance p.error {
    color: #e63946;
  }
  
  .vip-balance p.success {
    color: #38b000;
  }
  
  .vip-balance p.balance {
    font-weight: bold;
    font-size: 20px;
    color: #212529;
  }
  
  .vip-balance p.error {
    border: 1px solid #e63946;
    background-color: #f8d7da;
    padding: 10px;
    border-radius: 8px;
  }
  
  .vip-balance p.success {
    border: 1px solid #38b000;
    background-color: #d4edda;
    padding: 10px;
    border-radius: 8px;
  }
  


  /*css for vipplanbuy */
  /* Vip.css */

.vip {
    padding: 20px;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .vip h1 {
    font-size: 16px;
    color: #495057;
    line-height: 1.6;
    text-align: justify;
    background-color: #e9ecef;
    padding: 15px;
    border-left: 5px solid #007bff;
    border-radius: 5px;
    margin-bottom: 30px;
  }
  
  .vip h2 {
    color: #343a40;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
  
  .vip form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .vip label {
    font-size: 18px;
    color: #495057;
    display: block;
    margin-bottom: 10px;
  }
  
  .vip input[type="text"],
  .vip input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 16px;
  }
  


   .vip button{
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.5s ease;
  }
  
  .vip button:hover {
    background-color: #0056b3;
  }
  

  /* .vip button{
    margin-bottom: 15vh;
  } */


  .vip p {
    font-size: 16px;
    color: #495057;
    margin-top: 20px;
    text-align: center;
  }
  
  .vip .success-message {
    color: #38b000;
    font-weight: bold;
  }
  
  .vip .error-message {
    color: #e63946;
    font-weight: bold;
  }
  
  .vip form input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  


  /*css for vip task */
  /* Vip.css */

.vip-task {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  .vip-task h1 {
    font-size: 28px;
    color: #343a40;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
  
  .vip-task ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .vip-task ul li {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    font-size: 18px;
    color: #495057;
  }
  
  .vip-task ul li strong {
    color: #007bff;
  }
  
  .vip-task ul li a {
    color: #007bff;
    text-decoration: none;
    word-wrap: break-word;
  }
  
  .vip-task ul li a:hover {
    text-decoration: underline;
  }
  
  .vip-task p {
    font-size: 16px;
    color: #dc3545;
    text-align: center;
  }
  
  .loading,
  .error {
    font-size: 18px;
    color: #6c757d;
    text-align: center;
    margin-top: 20px;
  }



  /* Vip.css */

.vip-d {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .vip-d h3 {
    font-size: 18px;
    color: #333;
    line-height: 1.8;
    margin-bottom: 20px;
    font-weight: normal;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
  }
  
  .vip-d p {
    font-size: 16px;
    color: #007bff;
    background-color: #ffffff;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .vip-d p:hover {
    background-color: #e9ecef;
  }
  
  .vip-d p strong {
    color: #007bff;
    font-weight: bold;
  }
  

  /*css for withdraw*/
  .vip-withdraw-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .vip-withdraw-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .vip-withdraw-form form {
    display: flex;
    flex-direction: column;
  }
  
  .vip-withdraw-form form div {
    margin-bottom: 15px;
  }
  
  .vip-withdraw-form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #333;
  }
  
  .vip-withdraw-form input[type="text"],
  .vip-withdraw-form input[type="number"],
  .vip-withdraw-form textarea,
  .vip-withdraw-form select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
  }
  
  .vip-withdraw-form input[type="text"]:disabled,
  .vip-withdraw-form input[type="number"]:disabled,
  .vip-withdraw-form textarea:disabled,
  .vip-withdraw-form select:disabled {
    background-color: #f0f0f0;
  }
  
  .vip-withdraw-form button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .vip-withdraw-form button:disabled {
    background-color: #ccc;
  }
  
  .vip-withdraw-form button:hover:not(:disabled) {
    background-color: #218838;
  }
  
  .vip-withdraw-form p {
    margin-top: 15px;
    font-size: 14px;
  }
  
  .vip-withdraw-form p[style*="color: red"] {
    color: red;
  }
  
  .vip-withdraw-form p[style*="color: green"] {
    color: green;
  }
  
  